<template>
  <div>
    <div class="modal_header">
      <el-row >
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t('message.new_system') }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)" type="success">{{ $t('message.save_and_close') }}</el-button>
              <el-button @click="resetForm('form')">{{ $t('message.close') }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form ref="form" status-icon :model="form" :rules="rules" :label-position="'right'"  class="aticler_m" >

      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item :label="columns.name.title" prop="name">
            <el-input v-model="form.name" autocomplete="off"/>
          </el-form-item>
        </el-col>

        <el-col :span="10">
          <el-form-item :label="columns.domen.title" prop="domen">
            <el-input v-model="form.domen" autocomplete="off"/>
          </el-form-item>
        </el-col>

        <el-col :span="10">
          <el-form-item :label="columns.user_amount.title" prop="user_amount">
            <el-input type="number" v-model="form.user_amount" autocomplete="off"/>
          </el-form-item>
        </el-col>

        <el-col :span="10">
          <el-form-item :label="columns.purchase_type.title" prop="purchase_type">
            <el-select
                size="medium"
                v-model="form.purchase_type">
              <el-option
                  value="rent"
                  label="Аренда"
              />
              <el-option
                  value="purchase"
                  label="Покупка"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="10">
          <el-form-item :label="columns.purchase_price.title" prop="purchase_price">
            <el-input type="number" v-model="form.purchase_price" autocomplete="off"/>
          </el-form-item>
        </el-col>

        <el-col :span="10">
          <el-form-item prop="integration">
            <el-checkbox
                style="top: 20px"
                v-model="form.integration"
                :label="$t('message.integration')"
                border
                size="medium"
            />
          </el-form-item>
        </el-col>

        <el-col :span="10" v-if="form.integration">
          <el-form-item :label="columns.integration_price.title" prop="integration_price">
            <el-input type="number" v-model="form.integration_price" autocomplete="off"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex';
import form from '@/utils/mixins/form';
export default {
  mixins:[form],
  data(){
    return {
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  created(){},
  computed: {
    ...mapGetters({
      roles: 'systems/list',
      rules: 'systems/rules',
      model: 'systems/model',
      columns: 'systems/columns'
    })
  },
  methods:{
    ...mapActions({
      save: 'systems/store',
    }),
    submit(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then(res => {
              this.loadingButton = false;
              this.$alert(res.message);
              this.parent().listChanged()
              this.resetForm('form');
              this.$refs.tree.setCheckedNodes([]);
            })
            .catch(err => {
              this.loadingButton = false;
              this.$notify({
                title: 'Ошибка',
                type: "error",
                offset: 130,
                message: err.error.message
              });
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit('c-close',{drawer: 'drawerCreate'});
    }
  },
}
</script>
