<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
            class="
            col-7
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          "
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.systems") }}
          </h5>
          <crm-refresh @c-click="refresh()"/>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
                size="mini"
                :placeholder="$t('message.search')"
                prefix-icon="el-icon-search"
                v-model="filterForm.search"
                clearable
            />
          </div>
        </div>
        <div
            class="
            col-5
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <el-button
              v-can="'roles.create'"
              size="mini"
              @click="drawerCreate = true"
              icon="el-icon-circle-plus-outline"
          >
            {{ $t("message.create") }}
          </el-button>
          <export-excel
              v-can="'roles.excel'"
              class="btn excel_btn"
              :data="list"
              :fields="excel_fields"
              @fetch="controlExcelData()"
              worksheet="Роли"
              name="Роли.xls"
          >
            <el-button size="mini" icon="el-icon-document-delete">
              Excel
            </el-button>
          </export-excel>
          <crm-column-settings
              :columns="columns"
              :modelName="'roles'"
              @c-change="updateColumn"
          />
        </div>
      </div>
      <table class="table table-bordered table-hover" v-loading="loadingData">
        <thead>
        <tr>
          <crm-th
              :column="columns.id"
              :sort="sort"
              @c-change="updateSort"
          />
          <crm-th
              :column="columns.name"
              :sort="sort"
              @c-change="updateSort"
          />
          <crm-th
              :column="columns.domen"
              :sort="sort"
              @c-change="updateSort"
          />
          <crm-th
              :column="columns.user_amount"
              :sort="sort"
              @c-change="updateSort"
          />
          <crm-th
              :column="columns.purchase_type"
              :sort="sort"
              @c-change="updateSort"
          />
          <crm-th
              :column="columns.purchase_price"
              :sort="sort"
              @c-change="updateSort"
          />
          <crm-th
              :column="columns.integration"
              :sort="sort"
              @c-change="updateSort"
          />
          <crm-th
              :column="columns.integration_price"
              :sort="sort"
              @c-change="updateSort"
          />
          <crm-th
              :sort="sort"
              :column="columns.created_at"
              @c-change="updateSort"
          />
          <crm-th
              :sort="sort"
              :column="columns.updated_at"
              @c-change="updateSort"
          />
          <crm-th
              :sort="sort"
              :column="columns.settings"
              @c-change="updateSort"
          />
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
                clearable
                size="mini"
                class="id_input"
                v-model="filterForm.id"
                :placeholder="columns.id.title"
            />
          </th>
          <th v-if="columns.name.show">
            <el-input
                size="mini"
                v-model="filterForm.name"
                :placeholder="columns.name.title"
                clearable
            />
          </th>
          <th v-if="columns.domen.show"></th>
          <th v-if="columns.user_amount.show"></th>
          <th v-if="columns.purchase_type.show"></th>
          <th v-if="columns.purchase_price.show"></th>
          <th v-if="columns.integration.show"></th>
          <th v-if="columns.integration_price.show"></th>
          <th v-if="columns.created_at.show">
            <el-date-picker
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                v-model="filterForm.created_at"
                :placeholder="columns.created_at.title"
                size="mini"
            />
          </th>
          <th v-if="columns.updated_at.show">
            <el-date-picker
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                v-model="filterForm.updated_at"
                :placeholder="columns.updated_at.title"
                size="mini"
            />
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
        </thead>
        <transition-group name="flip-list" tag="tbody">
          <tr
              v-for="(system, ind) in list"
              :key="'systems' + ind"
              class="cursor-pointer"
          >
            <td v-if="columns.id.show">{{ system.id }}</td>
            <td v-if="columns.name.show">{{ system.name }}</td>
            <td v-if="columns.domen.show">{{ system.domen }}</td>
            <td v-if="columns.user_amount.show">{{ system.user_amount }}</td>
            <td v-if="columns.purchase_type.show">{{ system.purchase_type }}</td>
            <td v-if="columns.purchase_price.show">{{ system.purchase_price }}</td>
            <td v-if="columns.integration.show">{{ system.integration ? 'имеется' : 'отсутствует' }}</td>
            <td v-if="columns.integration_price.show">{{ system.integration_price }}</td>
            <td v-if="columns.created_at.show">
              {{ system.created_at }}
            </td>
            <td v-if="columns.updated_at.show">
              {{ system.updated_at  }}
            </td>
            <td v-if="columns.settings.show" class="settings-td">
              <crm-setting-dropdown
                  name="systems"
                  :model="system"
                  :actions="actions"
                  @edit="edit"
                  @delete="destroyModel"
              />
            </td>
          </tr>
        </transition-group>
      </table>
      <el-pagination
          @current-change="handleCurrentChange"
          class="mt-3 mypagination"
          background
          layout="prev, pager, next"
          :page-size="pagination.per_page"
          :total="pagination.total"
      />

      <!-- Creating Role model  -->
      <el-drawer
          class="popups_title"
          :visible.sync="drawerCreate"
          @close="reloadIfChanged"
          size="80%" :wrapperClosable="false"
      >
        <CrmCreate
            :reloadModel="reopenUpdate"
            @c-close="reloadIfChanged('drawerCreate')"
        />
      </el-drawer>
      <!-- Creating Role model  -->

      <!-- Updating Role model  -->
      <el-drawer
          class="popups_title"
          :visible.sync="drawerUpdate"
          direction="rtl"
          size="80%" :wrapperClosable="false"
          :drawer="drawerUpdate"
          @open="reopenUpdate = true"
          @close="reloadIfChanged('reopenUpdate')"
          @closed="emptyModel"
      >
        <CrmUpdate
            :reloadModel="reopenUpdate"
            @open="reopenUpdate = true"
            @c-close="closeDrawer"
        />
      </el-drawer>
      <!-- Updating Role model  -->
    </div>
  </div>
</template>
<script>
import CrmUpdate from "./components/update";
import CrmCreate from "./components/create";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  name: "RolesList",
  components: {
    CrmCreate,
    CrmUpdate,
  },
  data() {
    return {
      drawerCreate: false,
      drawerUpdate: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "systems/list",
      columns: "systems/columns",
      pagination: "systems/pagination",
      filter: "systems/filter",
      sort: "systems/sort",
      parent_permissions: "permissions/parent_permissions",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {},
  async mounted() {
    this.controlExcelData();

    // if (this.parent_permissions && this.parent_permissions.length === 0)
    //     await this.loadParentPermissions();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      updateSort: "systems/updateSort",
      updateFilter: "systems/updateFilter",
      updateColumn: "systems/updateColumn",
      updateList: "systems/index",
      updatePagination: "systems/updatePagination",
      editModel: "systems/show",
      empty: "systems/empty",
      delete: "systems/destroy",
      refreshData: "systems/refreshData",
      loadParentPermissions: "permissions/parent_permissions",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
            .then((res) => {
              this.loadingData = false;
            })
            .catch((err) => {
              this.loadingData = false;
            });
      }
    },
    handleCurrentChange(val) {
      this.updatePagination({ key: "page", value: val })
          .then((res) => {})
          .catch((err) => {});
    },
    refresh() {
      this.refreshData()
          .then((res) => {
            this.filterForm = JSON.parse(JSON.stringify(this.filter));
          })
          .catch((err) => {});
    },
    async edit(model) {
      await this.editModel(model.id)
          .then(async (res) => {
            this.drawerUpdate = true;
          })
          .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    destroyModel(model) {
      this.delete(model.id)
          .then((res) => {
            this.$notify({
              title: 'Успешно',
              type: "success",
              offset: 130,
              message: res.message
            });
            this.fetchData();
          })
          .catch((err) => {
            console.log(err);
          });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
